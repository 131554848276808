@import "./constants.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Karla", sans-serif;
  font-weight: lighter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-text-color);
}

h1,
h2 {
  font-size: 4rem;
  color: var(--secondary-text-color);
  font-weight: normal;
  text-align: center;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  scroll-behavior: smooth;
}

.wrapper {
  margin: auto;
  max-width: 75rem;
}
