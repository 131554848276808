@import "../../constants.css";

.nav-items {
  background: var(--primary-color);
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.nav-links {
  text-decoration: none;
  color: #fff;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: var(--contrast-color);
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #ffffff;
}

.fa-times {
  color: #ffffff;
}

.nav-logo {
  color: #fff;
  font-size: 2rem;
}

.nav-socials {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px;
  position: absolute;
  right: 4%;
}

.fa-phone-alt {
}

.social-link {
  color: #ffffff;
  height: 10%;
}

.social-link > i:hover {
  color: var(--contrast-color);
}

.menu-icon {
  display: none;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: center;
  margin-right: 2rem;
}

@media screen and (max-width: 800px) {
  .NavbarItems {
    position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*height: 100vw;*/
    position: absolute;
    top: 25px;
    left: -100%;
    transition: all 0.5s ease;
    background: var(--primary-color);
  }
  .nav-menu.active {
    left: 0;
    transition: all 0.5s ease;
    z-index: 1;
    opacity: 1;
    list-style: none;
    padding-left: 0;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }
  .nav-links:hover {
    border-radius: 0;
  }
  .menu-icon {
    display: block;
    position: absolute;
    left: 3%;
    font-size: 1.8rem;
    cursor: pointer;
  }
  .fa-times {
    font-size: 2rem;
  }
}
