.footer {
  padding-top: 5px;
  bottom: 0;
  height: 80px;
  font-size: 0.65rem;
  text-align: center;
  background: #696969;
  color: #fffaf0;
}

.footer > p {
  margin-top: 0;
  padding: 5px;
  margin-bottom: 0;
}

.author > a {
  color: floralwhite;
}
